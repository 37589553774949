<template>
  <div class="reportDetail">
    <div class="report-content">
      <div class="info">
        <div>
          <p>{{ reportData.name }} {{ reportData.testTime }}</p>
          <h1>脊柱侧弯筛查</h1>
        </div>
        <img src="@/assets/img/icon-report.svg" alt="">
      </div>
      <van-tabs v-model="active" type="card" color="#24BBD3">
        <van-tab title="ATI" :name="0">
          <div class="tab-content">
            <h2>ATI 测量</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="ati">
              <div class="line-x"></div>
              <div class="line-y"></div>
              <div class="left">左</div>
              <div class="right">右</div>
              <div class="explanation">胸腰分界线</div>
              <template v-if="atiValues.length">
                <div v-for="(item, i) in atiValues" :style="{top: i*0.005955882+'rem',left: item*0.0155+'rem'}" :key="i" class="svg-line"></div>
              </template>
            </div>
            <div v-if="reportData.ati" class="ati-info">
              <span>身体最大倾角：{{ reportData.ati.maxValue < 0 ? '左' : '右' }}侧 
                {{ reportData.ati.maxValue > 0 ? '+' : '' }}{{ reportData.ati.maxValue.toFixed(1) != 0 ? reportData.ati.maxValue.toFixed(1) : '0.0' }}°</span>
              <span>最大倾角位置：{{ reportData.ati.positon === 1 ? '胸' : '腰' }}段</span>
            </div>
            <div v-if="reportData.ati" class="remark"
                 :class="{warning: reportData.ati.standard===1&&reportData.ati.maxValue.toFixed(1)>7||reportData.ati.standard===2&&reportData.ati.maxValue.toFixed(1)>5}">
            {{ reportData.ati.remark }}
            </div>
          </div>
        </van-tab>
        <van-tab title="瞬时" :name="1">
          <div class="tab-content">
            <h2>瞬时精准测量</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="spineJointAtr">
              <div>
                <div class="region">颈椎段</div>
                <div :class="item.class?'spineJointAtrInfo '+item.class:'spineJointAtrInfo'" v-for="(item, i) in this.spineJointAtr1" :key="i">
                  {{ item.atrValues }}°
                </div>
              </div>
              <div>
                <div class="region">胸椎段</div>
                <div :class="item.class?'spineJointAtrInfo '+item.class:'spineJointAtrInfo'" v-for="(item, i) in this.spineJointAtr2" :key="i">
                  {{ item.atrValues }}°
                </div>
              </div>
              <div>
                <div class="region">腰椎段</div>
                <div :class="item.class?'spineJointAtrInfo '+item.class:'spineJointAtrInfo'" v-for="(item, i) in this.spineJointAtr3" :key="i">
                  {{ item.atrValues }}°
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="后凸" :name="2">
          <div class="tab-content">
            <h2>脊柱后凸</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="kyphosis">
              <div class="range-box">
                <div class="range"></div><div class="range"></div><div class="range"></div>
                <span class="t0">平背</span><span class="t0" style="left: 0.8rem;">正常</span><span class="t0" style="right: 0;">驼背</span>
                <span class="b0" style="left: 0.51rem;">20°</span><span class="b0" style="left: 1.13rem;">40°</span>
                <img src="@/assets/img/icon-normal.svg" alt=""
                     v-if="reportData.kyphosis&&reportData.kyphosis.value>20&&reportData.kyphosis.value<40"
                     :style="{left: reportData.kyphosis.value*0.031+'rem'}">
                <img src="@/assets/img/icon-abnormality.svg" alt=""
                     v-if="reportData.kyphosis&&(reportData.kyphosis.value<20||reportData.kyphosis.value>40)"
                     :style="{left: reportData.kyphosis.value*0.031+'rem'}">
              </div>
              <div v-if="reportData.kyphosis" class="kyphosis-info"
                   :class="{abnormality: reportData.kyphosis.value<20||reportData.kyphosis.value>40}">
                {{ reportData.kyphosis.value }}°&nbsp;&nbsp;{{ reportData.kyphosis.value < 20 ? '平背' : reportData.kyphosis.value > 40 ? '驼背' : '正常' }}
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="平衡" :name="3">
          <div class="tab-content">
            <h2>身体平衡度</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="body-balance">
              <img src="@/assets/img/body-balance.svg" alt="">
              <div>
                <div>头部&nbsp;&nbsp;<span v-if="reportData.balance">
                  {{ reportData.balance.neck.toFixed(1) > 0 ? '+' : '' }}{{ reportData.balance.neck.toFixed(1) != 0 ? reportData.balance.neck.toFixed(1) : '0.0' }}°
                </span></div>
                <div>肩部&nbsp;&nbsp;<span v-if="reportData.balance">
                  {{ reportData.balance.thoracic.toFixed(1) > 0 ? '+' : '' }}{{ reportData.balance.thoracic.toFixed(1) != 0 ? reportData.balance.thoracic.toFixed(1) : '0.0' }}°
                </span></div>
                <div>腰部&nbsp;&nbsp;<span v-if="reportData.balance">
                  {{ reportData.balance.waist.toFixed(1) > 0 ? '+' : '' }}{{ reportData.balance.waist.toFixed(1) != 0 ? reportData.balance.waist.toFixed(1) : '0.0' }}°
                </span></div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="ROM" :name="4">
          <div class="tab-content mb48">
            <h2>颈椎活动度</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="spineRom">
              <div class="title">侧向弯曲</div>
              <div class="range-box">
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.55rem;">-45°</span>
                <span class="b0" style="right: 0.53rem;">+45°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.neckLatLeft||reportData.spineRom.neckLatRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckLatLeft.toFixed(1)<=-45"
                       :style="{left: reportData.spineRom.neckLatLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.neckLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.neckLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckLatRight.toFixed(1)>=45"
                       :style="{right: reportData.spineRom.neckLatRight.toFixed(1)>=90?'0':(90-reportData.spineRom.neckLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.neckLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.neckLatLeft||reportData.spineRom.neckLatRight)">
                <span :class="{warning: reportData.spineRom.neckLatLeft.toFixed(1)>-45}">
                  左：{{ reportData.spineRom.neckLatLeft.toFixed(1) != 0 ? reportData.spineRom.neckLatLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckLatLeft.toFixed(1)>-45}">
                  {{ reportData.spineRom.neckLatLeft.toFixed(1) > -45 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.neckLatRight.toFixed(1)<45}">
                  右：{{ reportData.spineRom.neckLatRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.neckLatRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckLatRight.toFixed(1)<45}">
                  {{ reportData.spineRom.neckLatRight.toFixed(1) < 45 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">前后屈伸</div>
              <div class="range-box">
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <div class="range" style="width: 0.693rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.55rem;">-45°</span>
                <span class="b0" style="right: 0.53rem;">+45°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.neckExtension||reportData.spineRom.neckFlexion)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckExtension.toFixed(1)<=-45"
                       :style="{left: reportData.spineRom.neckExtension.toFixed(1)<=-90?'0':(90+reportData.spineRom.neckExtension).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.neckExtension).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckFlexion.toFixed(1)>=45"
                       :style="{right: reportData.spineRom.neckFlexion.toFixed(1)>=90?'0':(90-reportData.spineRom.neckFlexion.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.neckFlexion.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.neckExtension||reportData.spineRom.neckFlexion)">
                <span :class="{warning: reportData.spineRom.neckExtension.toFixed(1)>-45}">
                  后：{{ reportData.spineRom.neckExtension.toFixed(1) != 0 ? reportData.spineRom.neckExtension.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckExtension.toFixed(1)>-45}">
                  {{ reportData.spineRom.neckExtension.toFixed(1) > -45 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.neckFlexion.toFixed(1)<45}">
                  前：{{ reportData.spineRom.neckFlexion.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.neckFlexion.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckFlexion.toFixed(1)<45}">
                  {{ reportData.spineRom.neckFlexion.toFixed(1) < 45 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">水平旋转</div>
              <div class="range-box">
                <div class="range" style="width: 0.154rem;"></div>
                <div class="range" style="width: 1.232rem;"></div>
                <div class="range" style="width: 1.232rem;"></div>
                <div class="range" style="width: 0.154rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.154rem;">-80°</span>
                <span class="b0" style="right: 0.154rem;">+80°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.neckRotLeft||reportData.spineRom.neckRotRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckRotLeft.toFixed(1)<=-80"
                       :style="{left: reportData.spineRom.neckRotLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.neckRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.neckRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.neckRotRight.toFixed(1)>=80"
                       :style="{right: reportData.spineRom.neckRotRight.toFixed(1)>=90?'0':(90-reportData.spineRom.neckRotRight).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.neckRotRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.neckRotLeft||reportData.spineRom.neckRotRight)">
                <span :class="{warning: reportData.spineRom.neckRotLeft.toFixed(1)>-80}">
                  左：{{ reportData.spineRom.neckRotLeft.toFixed(1) != 0 ? reportData.spineRom.neckRotLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckRotLeft.toFixed(1)>-80}">
                  {{ reportData.spineRom.neckRotLeft.toFixed(1) > -80 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.neckRotRight.toFixed(1)<80}">
                  右：{{ reportData.spineRom.neckRotRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.neckRotRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.neckRotRight.toFixed(1)<80}">
                  {{ reportData.spineRom.neckRotRight.toFixed(1) < 80 ? '异常' : '正常' }}
                </span>
              </div>
            </div>
          </div>
          <div class="tab-content mb48">
            <h2>胸椎活动度</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="spineRom">
              <div class="title">侧向弯曲</div>
              <div class="range-box">
                <div class="range"></div><div class="range"></div><div class="range"></div><div class="range"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.784rem;">-30°</span>
                <span class="b0" style="right: 0.764rem;">+30°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.thoracicLatLeft||reportData.spineRom.thoracicLatRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicLatLeft.toFixed(1)<=-30"
                       :style="{left: reportData.spineRom.thoracicLatLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.thoracicLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.thoracicLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicLatRight.toFixed(1)>=30"
                       :style="{right: reportData.spineRom.thoracicLatRight.toFixed(1)>=90?'0':(90-reportData.spineRom.thoracicLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.thoracicLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.thoracicLatLeft||reportData.spineRom.thoracicLatRight)">
                <span :class="{warning: reportData.spineRom.thoracicLatLeft.toFixed(1)>-30}">
                  左：{{ reportData.spineRom.thoracicLatLeft.toFixed(1) != 0 ? reportData.spineRom.thoracicLatLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicLatLeft.toFixed(1)>-30}">
                  {{ reportData.spineRom.thoracicLatLeft.toFixed(1) > -30 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.thoracicLatRight.toFixed(1)<30}">
                  右：{{ reportData.spineRom.thoracicLatRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.thoracicLatRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicLatRight.toFixed(1)<30}">
                  {{ reportData.spineRom.thoracicLatRight.toFixed(1) < 30 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">前后屈伸</div>
              <div class="range-box">
                <div class="range"></div><div class="range"></div>
                <div class="range" style="width: 0.693rem;"></div><div class="range" style="width: 0.693rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.784rem;">-30°</span>
                <span class="b0" style="right: 0.53rem;">+90°</span><span class="b0" style="right: -0.2rem;">+180°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.thoracicExtension||reportData.spineRom.thoracicFlexion)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicExtension.toFixed(1)<=-30"
                       :style="{left: reportData.spineRom.thoracicExtension.toFixed(1)<=-90?'0':(180+reportData.spineRom.thoracicExtension).toFixed(1)*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (180+reportData.spineRom.thoracicExtension).toFixed(1)*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicFlexion.toFixed(1)>=90"
                       :style="{right: reportData.spineRom.thoracicFlexion.toFixed(1)>=180?'0':(180-reportData.spineRom.thoracicFlexion.toFixed(1))*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (180-reportData.spineRom.thoracicFlexion.toFixed(1))*0.0077-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.thoracicExtension||reportData.spineRom.thoracicFlexion)">
                <span :class="{warning: reportData.spineRom.thoracicExtension.toFixed(1)>-30}">
                  后：{{ reportData.spineRom.thoracicExtension.toFixed(1) != 0 ? reportData.spineRom.thoracicExtension.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicExtension.toFixed(1)>-30}">
                  {{ reportData.spineRom.thoracicExtension.toFixed(1) > -30 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.thoracicFlexion.toFixed(1)<90}">
                  前：{{ reportData.spineRom.thoracicFlexion.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.thoracicFlexion.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicFlexion.toFixed(1)<90}">
                  {{ reportData.spineRom.thoracicFlexion.toFixed(1) < 90 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">水平旋转</div>
              <div class="range-box">
                <div class="range"></div><div class="range"></div><div class="range"></div><div class="range"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.784rem;">-30°</span>
                <span class="b0" style="right: 0.764rem;">+30°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.thoracicRotLeft||reportData.spineRom.thoracicRotRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicRotLeft.toFixed(1)<=-30"
                       :style="{left: reportData.spineRom.thoracicRotLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.thoracicRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.thoracicRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.thoracicRotRight.toFixed(1)>=30"
                       :style="{right: reportData.spineRom.thoracicRotRight.toFixed(1)>=90?'0':(90-reportData.spineRom.thoracicRotRight.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.thoracicRotRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.thoracicRotLeft||reportData.spineRom.thoracicRotRight)">
                <span :class="{warning: reportData.spineRom.thoracicRotLeft.toFixed(1)>-30}">
                  左：{{ reportData.spineRom.thoracicRotLeft.toFixed(1) != 0 ? reportData.spineRom.thoracicRotLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicRotLeft.toFixed(1)>-30}">
                  {{ reportData.spineRom.thoracicRotLeft.toFixed(1) > -30 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.thoracicRotRight.toFixed(1)<30}">
                  右：{{ reportData.spineRom.thoracicRotRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.thoracicRotRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.thoracicRotRight.toFixed(1)<30}">
                  {{ reportData.spineRom.thoracicRotRight.toFixed(1) < 30 ? '异常' : '正常' }}
                </span>
              </div>
            </div>
          </div>
          <div class="tab-content mb48">
            <h2>腰椎活动度</h2>
            <img src="@/assets/img/icon-explanation.svg" alt="" class="icon-explanation" @click="show=true">
            <div class="spineRom">
              <div class="title">侧向弯曲</div>
              <div class="range-box">
                <div class="range"></div><div class="range"></div><div class="range"></div><div class="range"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.784rem;">-30°</span>
                <span class="b0" style="right: 0.764rem;">+30°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.waistLatLeft||reportData.spineRom.waistLatRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom&&reportData.spineRom.waistLatLeft.toFixed(1)<=-30"
                       :style="{left: reportData.spineRom.waistLatLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.waistLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.waistLatLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom&&reportData.spineRom.waistLatRight.toFixed(1)>=30"
                       :style="{right: reportData.spineRom.waistLatRight.toFixed(1)>=90?'0':(90-reportData.spineRom.waistLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.waistLatRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.waistLatLeft||reportData.spineRom.waistLatRight)">
                <span :class="{warning: reportData.spineRom.waistLatLeft.toFixed(1)>-30}">
                  左：{{ reportData.spineRom.waistLatLeft.toFixed(1) != 0 ? reportData.spineRom.waistLatLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistLatLeft.toFixed(1)>-30}">
                  {{ reportData.spineRom.waistLatLeft.toFixed(1) > -30 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.waistLatRight.toFixed(1)<30}">
                  右：{{ reportData.spineRom.waistLatRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.waistLatRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistLatRight.toFixed(1)<30}">
                  {{ reportData.spineRom.waistLatRight.toFixed(1) < 30 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">前后屈伸</div>
              <div class="range-box">
                <div class="range" style="width: 1.078rem;"></div><div class="range" style="width: 0.308rem;"></div>
                <div class="range" style="width: 0.385rem;"></div><div class="range" style="width: 1.001rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.918rem;">-20°</span>
                <span class="b0" style="right: 0.861rem;">+50°</span><span class="b0" style="right: -0.2rem;">+180°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.waistExtension||reportData.spineRom.waistFlexion)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.waistExtension.toFixed(1)<=-20"
                       :style="{left: reportData.spineRom.waistExtension.toFixed(1)<=-90?'0':(180+reportData.spineRom.waistExtension).toFixed(1)*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (180+reportData.spineRom.waistExtension).toFixed(1)*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.waistFlexion.toFixed(1)>=50"
                       :style="{right: reportData.spineRom.waistFlexion.toFixed(1)>=180?'0':(180-reportData.spineRom.waistFlexion.toFixed(1))*0.0077-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (180-reportData.spineRom.waistFlexion.toFixed(1))*0.0077-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.waistExtension||reportData.spineRom.waistFlexion)">
                <span :class="{warning: reportData.spineRom.waistExtension.toFixed(1)>-20}">
                  后：{{ reportData.spineRom.waistExtension.toFixed(1) != 0 ? reportData.spineRom.waistExtension.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistExtension.toFixed(1)>-20}">
                  {{ reportData.spineRom.waistExtension.toFixed(1) > -20 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.waistFlexion.toFixed(1)<50}">
                  前：{{ reportData.spineRom.waistFlexion.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.waistFlexion.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistFlexion.toFixed(1)<50}">
                  {{ reportData.spineRom.waistFlexion.toFixed(1) < 50 ? '异常' : '正常' }}
                </span>
              </div>
              <div class="line"></div>
              <div class="title">水平旋转</div>
              <div class="range-box">
                <div class="range" style="width: 1.078rem;"></div><div class="range" style="width: 0.308rem;"></div>
                <div class="range" style="width: 0.308rem;"></div><div class="range" style="width: 1.078rem;"></div>
                <span class="t0" style="left: -0.14rem;">正常</span><span class="t0" style="left: 1.235rem;">异常</span>
                <span class="t0" style="right: -0.16rem;">正常</span>
                <span class="b0" style="left: -0.14rem;">-90°</span><span class="b0" style="left: 0.918rem;">-20°</span>
                <span class="b0" style="right: 0.938rem;">+20°</span><span class="b0" style="right: -0.16rem;">+90°</span>
                <template v-if="reportData.spineRom&&(reportData.spineRom.waistRotLeft||reportData.spineRom.waistRotRight)">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.waistRotLeft.toFixed(1)<=-20"
                       :style="{left: reportData.spineRom.waistRotLeft.toFixed(1)<=-90?'0':(90+reportData.spineRom.waistRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{left: (90+reportData.spineRom.waistRotLeft).toFixed(1)*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-normal.svg" alt=""
                       v-if="reportData.spineRom.waistRotRight.toFixed(1)>=20"
                       :style="{right: reportData.spineRom.waistRotRight.toFixed(1)>=90?'0':(90-reportData.spineRom.waistRotRight.toFixed(1))*0.0154-0.03+'rem'}">
                  <img src="@/assets/img/icon-abnormality.svg" alt="" v-else
                       :style="{right: (90-reportData.spineRom.waistRotRight.toFixed(1))*0.0154-0.03+'rem'}">
                </template>
              </div>
              <div class="spineRomInfo" v-if="reportData.spineRom&&(reportData.spineRom.waistRotLeft||reportData.spineRom.waistRotRight)">
                <span :class="{warning: reportData.spineRom.waistRotLeft.toFixed(1)>-20}">
                  左：{{ reportData.spineRom.waistRotLeft.toFixed(1) != 0 ? reportData.spineRom.waistRotLeft.toFixed(1) : '0.0' }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistRotLeft.toFixed(1)>-20}">
                  {{ reportData.spineRom.waistRotLeft.toFixed(1) > -20 ? '异常' : '正常' }}
                </span>
                <div class="split-line"></div>
                <span :class="{warning: reportData.spineRom.waistRotRight.toFixed(1)<20}">
                  右：{{ reportData.spineRom.waistRotRight.toFixed(1) > 0 ? '+' : '' }}{{ reportData.spineRom.waistRotRight.toFixed(1) }}°
                </span>
                <span :class="{warning: reportData.spineRom.waistRotRight.toFixed(1)<20}">
                  {{ reportData.spineRom.waistRotRight.toFixed(1) < 20 ? '异常' : '正常' }}
                </span>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="show" position="bottom" safe-area-inset-bottom>
      <h2 v-if="active===0">什么是ATI</h2>
      <p v-if="active===0">ATI即身体倾斜角，即当受测者做体前屈动作时，身体最大倾角的角度测量。躯干不对称就是脊柱侧弯的一种表现形式，尤其是在您前屈时，表现最为明显。</p>
      <h2 v-if="active===1">什么是瞬时精准测量</h2>
      <p v-if="active===1">此数据为特定脊柱节点位置的瞬时角度，可以配合身体倾斜角测量辅助医生决策。我们会根据单个节点的倾斜严重程度，通过颜色标注在报告中进行提示。</p>
      <h2 v-if="active===2">什么是脊柱后凸</h2>
      <p v-if="active===2">脊柱后凸指的是脊柱前后弯曲异常。表现为站立时前胸部塌陷，头颈部前移、腹部向前突出、腰后部凹陷曲线加深，臀部明显后凸等不良体态。</p>
      <h2 v-if="active===3">什么是身体平衡度</h2>
      <p v-if="active===3">对身体部位的平衡度进行数据采集，可以判断受测者是否有斜肩、胯骨不平衡等体型不正风险，从而直观了解您的身体体态等非病理性信息。</p>
      <h2 v-if="active===4">什么是ROM</h2>
      <p v-if="active===4">即关节的活动度，也称关节活动度或关节运动度，指一个关节在特定的方向上能够运动的最大幅度。ROM常被医生用来评估关节的功能和肌肉骨骼系统的健康状况。</p>
      <div class="foot">
        <span @click="show=false">我知道了</span><img src="@/assets/img/popup.svg" alt="">
      </div>
    </van-popup>
    <div class="btn-wrap" v-if="reportData.prescription">
      <wx-open-launch-weapp
          id="launch-btn"
          appid="wx336e7bc529ba9672"
          path="/pages/courseList/courseList"
      >
        <script type="text/wxtag-template">
          <style>
          .btn{ 
            width: 21.4375rem;
            height: 3rem;
            font-size: 1.125rem; 
            border: none;
            color: #FFFFFF;
            outline: none;
            border-radius: 2.5rem;
            background-color: #24bbd3;
            box-shadow: 0 1px 2px -2px rgba(12, 130, 170, 0.2), 0 4px 18px 0 rgba(17, 162, 240, 0.3), inset -1px -1px 1px 1px rgba(255, 255, 255, 0.3), inset 1px 1px 1px 0.5px #FFFFFF;
          }
          </style>
          <button class='btn'>开始训练</button>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
import { reportDetail, getSignatureInfo } from "@/api/getData"
import wx from 'weixin-js-sdk'

export default {
  name: "SpinescaleReport",
  data() {
    return {
      reportData: {},
      active: 0,
      show: false,
      atiValues: [],
      spineJointAtr1: [],
      spineJointAtr2: [],
      spineJointAtr3: [],
    }
  },
  methods: {
    reportDetail(){
      reportDetail(this.$route.query.reportId).then(res => {
        this.reportData = res.data
        if (res.data.ati && res.data.ati.atiValues) {
          let arr = res.data.ati.atiValues.split(',')
          arr.forEach((item, i) => {
            this.atiValues.push(item)
            if (i < arr.length - 1) {
              this.atiValues.push((item * 1 + arr[i + 1] * 1) / 2)
            }
          })
        }
        if (res.data.spineJointAtr && res.data.spineJointAtr.atrValues) {
          let arr = res.data.spineJointAtr.atrValues.split(',')
          arr.forEach(item => {
            item = item.replace(':', '：')
            let str1 = item.slice(0, item.indexOf('：'))
            let str2 = (item.slice(item.indexOf('：') + 1) * 1).toFixed(1)
            let cls = ''
            if (str2 >= 45 || res.data.spineJointAtr.standard === 2 && str2 >= 10) {
              cls = 'severe'
            } else if (res.data.spineJointAtr.standard === 1 && str2 >= 7 || res.data.spineJointAtr.standard === 2 && str2 >= 7) {
              cls = 'moderate'
            } else if (res.data.spineJointAtr.standard === 2 && str2 >= 5 && str2 < 7) {
              cls = 'mild'
            }
            item = { 
              atrValues: str1 + '：' + str2,
              class: cls
            }
            if (item.atrValues.includes('C')) {
              this.spineJointAtr1.push(item)
            } else if (item.atrValues.includes('T')) {
              this.spineJointAtr2.push(item)
            } else if (item.atrValues.includes('L')) {
              this.spineJointAtr3.push(item)
            }
          })
        }
        if (res.data.kyphosis && res.data.kyphosis.value) {
          this.reportData.kyphosis.value = res.data.kyphosis.value.toFixed(1)
        }
        if(this.reportData.prescription){
          this.initWxAppConfig()
        }
      })
    },
    /**
     * 如果有处方 初始化训练按钮
     */
    initWxAppConfig(){
      let url=location.href.split('#')[0]
      let data = {
        clientUrl : url
      }
      getSignatureInfo(data).then(res => {
        let data = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx1b2221702e991339', // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['onMenuShareTimeline'], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        })
        // 通过ready接口处理成功验证
        wx.ready(function () {
          // console.log('按钮可以点击')
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
        })
        // 通过error接口处理失败验证
        wx.error(function (res) {
          // console.log(res)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        })
      })
    },
  },
  mounted() {
    this.reportDetail()
  },
  created() {
    
  }
}
</script>

<style lang="scss" scoped>
.reportDetail {
  padding: 0.16rem 0.16rem 0;
  background: #FDFFFE;

  .report-content {
    min-height: 5.21rem;
    border-radius: 0.16rem;
    background: linear-gradient(175deg, rgba(9, 243, 243, 0.10) 7%, rgba(130, 194, 19, 0.00) 97%);
  }

  .info {
    padding: 0.1rem 0 0 0.16rem;
    margin-bottom: 0.11rem;
    display: flex;
    justify-content: space-between;

    > div {
      margin-top: 0.15rem;
    }

    p {
      height: 0.18rem;
      line-height: 0.18rem;
      margin-bottom: 0.16rem;
      font-size: 0.14rem;
      font-weight: 600;
      color: #2d5a61;
    }

    h1 {
      font-size: 0.3rem;
      color: #24bbd3;
    }

    img {
      width: 1.24rem;
      height: 0.99rem;
    }
  }

  .btn-wrap {
    box-sizing: border-box;
    height: 0.76rem;
    padding: 0.14rem 0.16rem;
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: -0.04rem -0.02rem 0.1rem -0.02rem rgba(30, 150, 150, 0.18);
  }

  :deep(.van-tabs--card) {
    > .van-tabs__wrap {
      height: 0.4rem;
    }

    .van-tabs__nav--card {
      height: 0.4rem;
      border-radius: 0.24rem;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.15rem;

      .van-tab {
        width: 0.62rem;
        height: 0.4rem;
        font-size: 0.14rem;
        border: none;
        border-radius: 0.24rem;
      }

      .van-tab:not(.van-tab--active) {
        color: #333333 !important;
      }
    }

    .van-tab__pane {
      padding: 0.24rem 0 0.76rem;
    }

    .van-tab__pane:last-child {
      padding-top: 0.16rem;
    }

    .tab-content {
      position: relative;
      padding-top: 0.24rem;
      margin: 0 0.08rem;
      border-radius: 0.16rem;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0 0.04rem 0.1rem 0 rgba(124, 209, 219, 0.16);

      h2 {
        width: 2.71rem;
        line-height: 0.52rem;
        text-align: center;
        font-size: 0.18rem;
        color: #0596ad;
        background: url(../../assets/img/title_bg.png) no-repeat;
        background-size: 100%;
        margin: 0 auto 0.24rem;
      }

      .icon-explanation {
        width: 0.16rem;
        position: absolute;
        top: 0.42rem;
        right: 0.68rem;
      }

      .ati {
        width: 2.79rem;
        height: 1.62rem;
        position: relative;
        margin: 0 auto 0.24rem;
        border-radius: 0.08rem;
        background-color: rgba(215, 243, 247, 0.4);

        > div {
          position: absolute;
        }

        .line-x {
          width: 2.77rem;
          height: 0.01rem;
          left: 0;
          bottom: 0.58rem;
          background-color: rgba(153, 192, 199, 0.6);
        }

        .line-y {
          width: 0.01rem;
          height: 1.62rem;
          top: 0;
          left: 1.39rem;
          z-index: 1;
          background-color: rgba(153, 192, 199, 0.6);
        }

        .left, .right, .explanation {
          font-size: 0.14rem;
          top: 0.16rem;
          color: rgba(32, 108, 120, 0.4);
        }

        .left {
          left: 0.16rem;
        }

        .right {
          right: 0.16rem;
        }

        .explanation {
          top: 1.12rem;
          right: 0.08rem;
        }

        .svg-line {
          position: absolute;
          width: 0.0155rem;
          height: 0.005955882rem;
          transform: translateX(-50%);
          background-color: #000000;
          z-index: 6;
        }
      }

      .ati-info {
        font-size: 0.14rem;
        text-align: center;
        margin-bottom: 0.24rem;
        
        span + span {
          margin-left: 0.1rem;
        }
      }

      .remark {
        padding: 0 0.2rem 0.24rem;
        font: 500 0.18rem Source Han Sans;
        text-align: center;
      }

      .spineJointAtr {
        margin: 0 0.16rem;
        padding-bottom: 0.24rem;
        display: flex;

        > div {
          flex: 1;
        }

        .region {
          width: 0.87rem;
          height: 0.52rem;
          text-align: center;
          font: 500 0.18rem Source Han Sans;
          line-height: 0.52rem;
          border-radius: 0.06rem;
          color: rgba(36, 187, 211, 0.8);
          background-color: rgba(36, 187, 211, 0.1);
        }

        > div:nth-child(2) {
          border-left: 0.01rem solid #f5f5f5;
          border-right: 0.01rem solid #f5f5f5;

          .region {
            margin: 0 auto;
          }
        }

        > div:nth-child(3) {
          margin-left: 0.08rem;
        }

        .spineJointAtrInfo {
          font-size: 0.14rem;
          line-height: 0.28rem;
          text-align: center;
          color: #838485;
        }

        .mild {
          color: #8a38f5;
        }

        .moderate {
          color: #ff8f1f;
        }

        .severe {
          color: #fa5151;
        }
      }

      .kyphosis {
        padding-top: 0.08rem;
        font-family: Source Han Sans;

        .range-box {
          height: 0.68rem;
          margin: 0 0.24rem 0.48rem;
          position: relative;
          display: flex;
          align-items: center;

          .range {
            height: 0.2rem;
          }

          .range:first-child, .range:nth-child(2) {
            width: 0.62rem;
          }

          .range:nth-child(2) {
            background-color: #94dfc8;
          }

          .range:first-child, .range:nth-child(3) {
            background-color: #f9b6b9;
          }

          .range:nth-child(3) {
            flex: 1;
          }

          img {
            width: auto;
            height: 0.34rem;
            position: absolute;
            top: 0.17rem;
            transform: translateX(-50%);
          }

          > span {
            position: absolute;
            font-size: 0.14rem;
            font-weight: 500;
            color: rgba(32, 108, 120, 0.4);
          }

          .t0 {
            top: 0;
          }

          .b0 {
            bottom: 0;
          }
        }

        .kyphosis-info {
          font-size: 0.18rem;
          text-align: center;
          padding-bottom: 0.24rem;
        }
      }

      .body-balance {
        padding-top: 0.08rem;
        display: flex;
        justify-content: space-between;
        font-family: Source Han Sans;

        img {
          width: 2rem;
        }

        > div {
          min-width: 1.1rem;

          div {
            font-size: 0.18rem;
            line-height: 0.32rem;
          }

          div:first-child {
            padding: 0.11rem 0 0.45rem 0;
          }

          div:nth-child(2) {
            margin-bottom: 0.49rem;
          }
        }
      }

      .spineRom {
        padding-top: 0.08rem;

        .title {
          font: 500 0.14rem Source Han Sans;
          margin: 0 0 0.32rem 0.24rem;
        }

        .range-box {
          height: 0.68rem;
          margin: 0 0.24rem 0.35rem;
          position: relative;
          display: flex;
          align-items: center;

          .range {
            height: 0.2rem;
          }

          .range:first-child, .range:nth-child(4) {
            width: 0.924rem;
            background-color: #94dfc8;
          }

          .range:nth-child(2), .range:nth-child(3) {
            width: 0.462rem;
            background-color: #f9b6b9;
          }

          .range:nth-child(2) {
            margin-right: 0.018rem;
          }

          img {
            width: auto;
            height: 0.34rem;
            position: absolute;
            top: 0.17rem;
          }

          span {
            position: absolute;
            font: 500 0.14rem Source Han Sans;
            color: rgba(32, 108, 120, 0.4);
          }

          .t0 {
            top: 0;
          }

          .b0 {
            bottom: 0;
          }
        }

        .spineRomInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0.24rem;
          padding-bottom: 0.26rem;
          position: relative;
          font-size: 0.14rem;
          color: #092020;

          .split-line {
            width: 0.02rem;
            height: 0.19rem;
            border-radius: 0.1rem;
            background-color: rgba(216, 216, 216, 0.4);
          }
        }

        .line {
          height: 0.01rem;
          margin: 0.08rem 0 0.32rem;
          background-color: #f5f5f5;
        }
      }

      .warning {
        color: rgba(250, 81, 81, 0.8) !important;
      }
    }

    .mb48 {
      margin-bottom: 0.48rem;
    }
  }

  :deep(.van-overlay) {
    background-color: rgba(38, 43, 47, 0.1);
  }

  :deep(.van-popup) {
    height: 4.26rem;
    border-radius: 0.1rem 0.1rem 0 0;

    h2 {
      text-align: center;
      line-height: 0.71rem;
      font-size: 0.18rem;
      color: #0596ad;
      border-bottom: 0.01rem solid #f5f5f5;
      margin-bottom: 0.24rem;
    }

    p {
      font-size: 0.18rem;
      font-family: Source Han Sans;
      padding: 0 0.24rem;
      line-height: 0.315rem;
      margin-bottom: 0.32rem;
    }

    .foot {
      padding-left: 0.12rem;
      display: flex;
      justify-content: space-between;

      span {
        width: 1.04rem;
        height: 0.56rem;
        line-height: 0.56rem;
        text-align: center;
        font-size: 0.18rem;
        font-weight: 700;
        color: #0596ad;
        cursor: pointer;
      }

      img {
        width: 2.26rem;
      }
    }
  }
}
</style>